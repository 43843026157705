/* eslint-disable indent */

import React, { useEffect, useState } from "react";
import styles from "../../components/customer-offer-price-journey/customer-offer-price-journey.module.scss";
import paiInfo from "../../utils/raise-pai/pai-info";
import calculateProbablity, { PROBABLITY_TYPES } from "../../utils/raise-pai/calculate-probablity";
import PaiSlider from "../../components/pai-slider";
import priceFormatter from "../../utils/helpers/price-formatter";
import { showToastMessages, isPllUser, getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, SLIDER_TYPES } from "../../utils/app-constants";
import Button from "../shared/button/component";
import IncrementalTextbox from "../incremental-textbox/component";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { AppointmentService } from "../../service";
import { isC2CUser, showPaiRaiseWarning,getUid} from "../../utils/helper";
import Popup from "../../components/popup";
import WarningPopUp from "../../components/warning-popup";
import ReasonConfirmPopup from "../../components/reason-confirm-popup";
import {trackLMSEvents} from "../../utils/helpers/tracking-events";
import moment from "moment";
const useStyles = makeStyles(theme => {
  return {
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: '10px',
      width: '100%'
    }
  };
});

const CustomerOfferPaiSlider = ({
  negotiations,
  preCheckRaisePAIConnect,
  paiRequestConnect
}) => {
  let isC2C = isC2CUser();
  const classes = useStyles();
  const { data } = negotiations;
  let appointMentData = {};
  if (!!data && data.length > 0) {
    appointMentData = data[0];
  }
  const { customerName, make, model, year, currentHB,
    bestHB, preCheckData, carRegion, appointmentId, c2cListingPrice, c24Quote, calculatedc24Quote } = appointMentData;

  const [warningPopUp, setWarningPopUp] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [calculatedPaiInfo, setCalculatedPaiInfo] = useState({});
    const [probablityType, setProbablityType] = useState(null);
  const [inputTextError, setInputTextError] = useState(false);
  const [inputTextErrorMessage, setInputTextErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(true);
  const [isLoading, setisLoading] = useState(false);
  const [preCheckPaierror, setpreCheckPaierror] = useState("");
  const [reasonConfirmPopupOpen, setReasonConfirmPopupOpen] = useState(false);

  const onReasonConfirmPopupOpen = () => {
      if (probablityType === PROBABLITY_TYPES.VERY_LOW.key || probablityType === PROBABLITY_TYPES.LOW.key) {
          setReasonConfirmPopupOpen(true);
        } else {
            setisLoading(false);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    };
    const onReasonConfirmPopupClose = () => {
        setReasonConfirmPopupOpen(false);
        setisLoading(false);
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    };
    const {
        currentAppointmentData,
        showSlider,
        minPrice,
        maxPrice,
        pendingCount,
        domain,
        defaultValues,
        sliderData,
        // calculatedc24Quote,
        priceAcceptance,
        optimalIncrease,
        step,
        sliderType,
        uuid,
        optimal
    } = calculatedPaiInfo;
    const [closurePrice, setClosurePrice] = useState(optimal);
    const [inputText, setInputText] = useState(optimal);
    let isPll = isPllUser();
  let isPaiRaiseWarning = showPaiRaiseWarning();
  const [values, setValues] = useState((defaultValues || []).slice());
  const [update, setUpdate] = useState((defaultValues || []).slice());
  const [acceptance, setAcceptance] = useState("");
  
  useEffect(() => {
    if (appointmentId > 0) {
      const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");
      if (!loginData && !loginData.email) { showToastMessages("Please login again.", false); }
      const payload = {
        "appointment_id": appointMentData.appointmentId,
        "carId": appointMentData.carId,
        "auction_id": appointMentData.auctionId,
        "store_id": appointMentData.storeId,
        "c24quote": appointMentData.calculatedc24Quote,
        "requested_c24quote": 0,
        "userEmail": loginData.email,
        "request_type": "RAISE_PAI",
        "is_pll": isPll,
        "c24Diff": 0,
        "probability": "",
        "reqCheck": "paiRequest",
        "customerPage": true
      };
      preCheckRaisePAIConnect(payload).then(() => {
        setCalculatedPaiInfo(paiInfo(negotiations, 1000, true));
      })
        .catch((resp) => {
          setpreCheckPaierror(resp);
          // showToastMessages(resp, false);
        });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      setClosurePrice(optimal > 0 ? optimal : minPrice);
    const currentState = calculateState(appointMentData);
    setAcceptance(currentState);
    if (showSlider) {
        setProbablityType(calculateProbablity(priceAcceptance, optimal, minPrice));
      setValues(defaultValues.slice());
      setUpdate(defaultValues.slice());
      if (!isC2C) {
        // setIsSubmitted(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSlider]);
  const onChangeClick = (type) => {
    if (!isC2C) {
      setIsSubmitted(false);
    }
    const newPrice = type === "increment" ? closurePrice + step : closurePrice - step;
    if (newPrice >= domain[0] && newPrice <= domain[1]) {
      setClosurePrice(newPrice);
        setProbablityType(calculateProbablity(priceAcceptance, newPrice, minPrice));
      setValues([newPrice]);
    }
  };
  const onUpdateSlider = update => {
    if (!isC2C) {
      setIsSubmitted(false);
    }
    const currentValue = update[0];
      setProbablityType(calculateProbablity(priceAcceptance, currentValue, minPrice));
    setUpdate(update);
    setClosurePrice(currentValue);
  };

  const onChangeSlider = values => {
    setValues(values);
    trackLMSEvents("ls_PAI", {
      eventLabel: appointmentId,
      lsRAID: getUid(),
      event:'ls_PAI',
      eventCategory:'Negotiated',
      eventAction:'SliderMoved',
      Timestamp: moment().format()
  });
  };

  const calculateState = (payload) => {
    const { conversion } = payload;
    const conversionData = conversion || {};
    const { max_delta_band } = conversionData;
    if (!!max_delta_band) {
      if (max_delta_band === "H") return "H";
      if (max_delta_band === "M") return "M";
      if (max_delta_band === "L") return "L";
      if (max_delta_band === "VL") return "L";
    }
    else { return "L"; }

  };
  const onChangeText = (e) => {
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    const cPrice = Number(e.target.value.replace(/,/g, ''));
    setClosurePrice(cPrice);
    setInputText(value);
    const isLimitCheckRequired = sliderType !== SLIDER_TYPES.TEXTBOX_NO_LIMIT;
    if (cPrice % 1000 !== 0) {
      setInputTextError(true);
      setInputTextErrorMessage("Price must be in multiple of 1000s");
    } else if (cPrice < minPrice && isLimitCheckRequired) {
      setInputTextError(true);
      setInputTextErrorMessage(`Price cannot be less than ${minPrice}`);
    } else if (cPrice > maxPrice && isLimitCheckRequired) {
      setInputTextError(true);
      setInputTextErrorMessage(`Price cannot be greater than ${maxPrice}`);
    } else {
      setInputTextError(false);
      if (!isC2C) {
        setIsSubmitted(false);
      }
      setInputTextErrorMessage("");
    }
  };

  const ShowWarningAndRaisePAI = () => {
    if (probablityType === PROBABLITY_TYPES.VERY_LOW.key && isPaiRaiseWarning) {
      setWarningPopUp(true);
      setWarningMessage("Attention: You have been raising significant number of PAI in Very Low Probability. If you continue to do so, you will be blocked from raising PAI in Very Low Probability");
    }
    else {
      onSubmitRaisePai();
    }
  };

  const onWarningPopUpClose = (bSubmit) => {
    setWarningPopUp(false);
    if (bSubmit === true) {
      onSubmitRaisePai();
    }
  };

  const onSubmitRaisePai = () => {
    setIsSubmitted(true);
    setisLoading(true);
    const {
      appointmentId,
      carId,
      storeId,
      calculatedc24Quote,
      auctionId
    } = appointMentData;
    const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");
    if (!loginData && !loginData.email) { showToastMessages("Please login again.", false); }
    const payload = {
      "appointment_id": appointmentId,
      "carId": carId,
      "auction_id": auctionId,
      "store_id": storeId,
      "c24quote": calculatedc24Quote,
      "current_c24quote": calculatedc24Quote,
      "requested_c24quote": closurePrice,
      "userEmail": loginData.email,
      "request_type": "RAISE_PAI",
      "is_pll": isPll,
      "c24Diff": closurePrice - calculatedc24Quote,
      "probability": !!probablityType ? probablityType : "",
      "uuid":uuid,
    };
    paiRequestConnect(payload)
      .then((resp) => {
        showToastMessages(resp && resp.message ? resp.message : resp);
        // setisLoading(false);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
        onReasonConfirmPopupOpen(payload); //TODO
      })
      .catch((resp) => {
        showToastMessages(resp, false);
        setIsSubmitted(false);
        setisLoading(false);
      });
  };
  return (
    <div>
      {!!sliderType &&  <div className={styles.priceTextAmountWrapper}>
        {SLIDER_TYPES.SLIDER !== sliderType &&
          <div>
            <TextField
              error={inputTextError}
              label="Guaranteed Closure Price"
              className={classes.textField}
              margin="dense"
              variant="outlined"
              onChange={onChangeText}
              value={inputText}
              inputProps={{
                maxLength: 9,
              }}
              InputLabelProps={{ style: {} }}
            />
          </div>
        }
        {sliderType ===  SLIDER_TYPES.SLIDER &&
          <IncrementalTextbox
            value={priceFormatter(closurePrice)}
            onChangeClick={onChangeClick}
          />
        }
        {sliderType ===  SLIDER_TYPES.SLIDER &&
          <div className={styles.priceTextAmount}>
            {/* <span className={styles.optimumAmount}>₹ {priceFormatter(values[0])}</span> */}
            <div className={styles.priceWrapper}>
              <div className={styles.priceSlider}>
                <PaiSlider
                  domain={domain}
                  onChange={onChangeSlider}
                  onUpdate={onUpdateSlider}
                  values={values}
                  update={update}
                  step={step}
                  sliderData={sliderData}
                  prices={priceAcceptance}
                />
              </div>
              <React.Fragment>
                {probablityType === PROBABLITY_TYPES.HIGH.key && <p className={styles.greenText}>{PROBABLITY_TYPES.HIGH.label}</p>}
                {probablityType === PROBABLITY_TYPES.MEDIUM.key && <p className={styles.orengeText}>{PROBABLITY_TYPES.MEDIUM.label}</p>}
                {probablityType === PROBABLITY_TYPES.LOW.key && <p className={styles.redText}>{PROBABLITY_TYPES.LOW.label}</p>}
                {probablityType === PROBABLITY_TYPES.VERY_LOW.key && <p className={styles.redText}>{PROBABLITY_TYPES.VERY_LOW.label}</p>}
              </React.Fragment>
            </div>
          </div>
        }
        {inputTextError && <p style={{ color: '#f44336', fontSize: 12, margin: 0, paddingBottom: 5, }}>{inputTextErrorMessage}</p>}
        {SLIDER_TYPES.TEXTBOX_WITH_LIMIT === sliderType && <p className={styles.textLabel}>{minPrice > 0 && maxPrice > 0 ? `Please enter between ${minPrice} & ${maxPrice}` : ``} </p>}
      </div>}
      {/* {showSlider === false && <span className={styles.noData}>No data available</span>} */}
      <div>
        <Popup isOpen={reasonConfirmPopupOpen} close={onReasonConfirmPopupClose}>
          <ReasonConfirmPopup onClose={onReasonConfirmPopupClose} appointmentId={appointMentData.appointmentId} auctionId={appointMentData.auctionId} />
        </Popup>
      </div>

      <div className={styles.ctaWrapper}>
        {/* <Button ctaText={"Raise PAI"} classNames="blueButton" onClick={onSubmitRaisePai} /> */}
        <Button
          isLoading={isLoading}
          disabled={inputTextError || isSubmitted}
          onClick={ShowWarningAndRaisePAI}
          classNames="blueButton"
          ctaText="Raise PAI" />
      </div>
      <Popup isOpen={warningPopUp} close={onWarningPopUpClose}>
        <WarningPopUp onClose={onWarningPopUpClose} warningMsg={warningMessage} />
      </Popup>
      <p className={styles.errorText}>{!!preCheckPaierror ? preCheckPaierror : ""}</p>
    </div>
  );
};

export default CustomerOfferPaiSlider;
