import Types from "./types";
import { AppointmentService } from "../../service";
import { getInNegotiationListMoc } from "../../utils/mocApi";
import { APPOINTMENT_TYPES_STRING } from "../../utils/app-constants";
import {updateApiNoAccess} from "../../components/hoc-access-controller/actions";
const fetchNegotiations = (page, searchQuery, filterQuery, isC2C, range, lostLeadQuery, pllSideBarFilter, retailSideBarFilter, withCustomer, type='', missedCallQuery) => dispatch => {
  if (type === APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION) {
    return AppointmentService.getDiyNegotiations(page, searchQuery, filterQuery, isC2C, range, lostLeadQuery, pllSideBarFilter, retailSideBarFilter, withCustomer, missedCallQuery)
      .then(response => {
        // response = getInNegotiationListMoc();
        const { data: { detail } } = response;
        dispatch(fetchNegotiationsSuccess(detail));
        return response;
      })
      .catch(error => {
        dispatch(updateApiNoAccess(error));
        dispatch(fetchNegotiationsFailure(error));
      });
  } else if (APPOINTMENT_TYPES_STRING.CENTRAL_NEGOTIATION===type) {
    return AppointmentService.getRTNegotiations(page, searchQuery, filterQuery, isC2C, range, lostLeadQuery, pllSideBarFilter, retailSideBarFilter, withCustomer, missedCallQuery)
      .then(response => {
        const { data: { detail } } = response;
        dispatch(fetchNegotiationsSuccess(detail));
        return response;
      })
      .catch(error => {
        dispatch(updateApiNoAccess(error));
        dispatch(fetchNegotiationsFailure(error));
      });
  } else if (APPOINTMENT_TYPES_STRING.CENTRAL_DIY_NEGOTIATION===type) {
    return AppointmentService.getRTDIYNegotiations(page, searchQuery, filterQuery, isC2C, range, lostLeadQuery, pllSideBarFilter, retailSideBarFilter, withCustomer, missedCallQuery)
      .then(response => {
        const { data: { detail } } = response;
        dispatch(fetchNegotiationsSuccess(detail));
        return response;
      })
      .catch(error => {
        dispatch(updateApiNoAccess(error));
        dispatch(fetchNegotiationsFailure(error));
      });
  } else {
    return AppointmentService.getNegotiations(page, searchQuery, filterQuery, isC2C, range, lostLeadQuery, pllSideBarFilter, retailSideBarFilter, missedCallQuery)
      .then(response => {
        // response = getInNegotiationListMoc();
        const { data: { detail } } = response;
        dispatch(fetchNegotiationsSuccess(detail));
        return response;
      })
      .catch(error => {
        dispatch(updateApiNoAccess(error));
        dispatch(fetchNegotiationsFailure(error));
      });
  }
};
const fetchMissedCalls = (type) => dispatch => {
  return AppointmentService.getMissedCallData(type === APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION ? 1 : 0).then((resp)=>{
    dispatch(fetchMissedCallsSuccess(resp?.detail?.missed_call));
    return resp;
  }).catch((err)=>{
    console.log({err});
    dispatch(updateApiNoAccess(err));
    dispatch(fetchMissedCallsFailure());
  });
};
const fetchMissedCallsSuccess = (data) => ({
  type: Types.FETCH_MISSEDCALLS_SUCCESS,
  data
});

const fetchMissedCallsFailure = (error) => ({
  type: Types.FETCH_MISSEDCALLS_FAILURE,
  error
});

const fetchNegotiationsSuccess = (data) => ({
  type: Types.FETCH_NEGOTIATIONS_SUCCESS,
  data
});

const fetchNegotiationsFailure = (error) => ({
  type: Types.FETCH_NEGOTIATIONS_FAILURE,
  error
});

const fetchCarData = (appointmentId) => dispatch => {
  return new Promise((resolve, reject) => {
    return AppointmentService.getCarData(appointmentId)
      .then(({ data }) => {
        const { detail } = data;
        dispatch(fetchCarDataSuccess(detail.data, appointmentId));
        resolve(detail.data);
      })
      .catch(error => {
        dispatch(updateApiNoAccess(error));
        dispatch(fetchCarDataFailure(error));
        reject(error);
      });
  });
};

const fetchCarDataSuccess = (data, appointmentId) => ({
  type: Types.FETCH_CAR_DATA_SUCCESS,
  data,
  appointmentId
});

const fetchCarDataFailure = (error) => ({
  type: Types.FETCH_CAR_DATA_FAILURE,
  error
});

const setCurrentPopup = (currentPopup = null, currentAppointmentIndex = null, isMgpButtonClicked = false) => (
  {
    type: Types.SET_CURRENT_POPUP,
    currentPopup,
        currentAppointmentIndex,
        isMgpButtonClicked
  });

const precCheckTokenElite = (appointmentId) => dispatch => {
  dispatch(preChecking());
  return new Promise((resolve, reject) => {
    return AppointmentService.tokenEliteABTest(appointmentId)
      .then(response => {
        const { data: { detail } } = response;
        let _detail = Object.assign({}, detail);
        _detail.appointment_id = appointmentId;
        dispatch(fetchPreCheckTokenEliteSuccess(_detail));
        resolve(detail.data);
        return response;
      })
      .catch(error => {
        dispatch(updateApiNoAccess(error));
        const { detail: data } = error;
        reject(data);
      });
  });
};
const fetchPreCheckTokenEliteSuccess = (data) => ({
  type: Types.PRECHECK_TOKEN_ELITE_SUCCESS,
  data
});

const preCheckRaisePAI = (payload) => dispatch => {
  dispatch(preChecking());
  return new Promise((resolve, reject) => {
    return AppointmentService.preCheck(payload)
      .then(response => {
        const { data: { detail } } = response;
                
        dispatch(fetchPreCheckRaisePAISuccess(detail));
        resolve(detail);
        return response;
      })
      .catch(error => {
        dispatch(updateApiNoAccess(error));
        const { detail } = error;
        dispatch(fetchPreCheckRaisePAIFailure(detail));
        reject(detail);
        // throw (error);
      });
  });
};
const preChecking = () => ({
  type: Types.PRECHECK_RAISE_PAI
});

const fetchPreCheckRaisePAISuccess = (data) => ({
  type: Types.PRECHECK_RAISE_PAI_SUCCESS,
  data
});

const fetchPreCheckRaisePAIFailure = (error) => ({
  type: Types.PRECHECK_RAISE_PAI_FAILURE,
  error
});

const paiRequest = (payload) => dispatch => {
  return new Promise((resolve, reject) => {
    return AppointmentService.paiRequest(payload)
      .then(response => {
        const { data: { detail } } = response;
        dispatch(paiRequestSuccess(detail));
        resolve(detail);
        return response;
      })
      .catch(error => {
        dispatch(updateApiNoAccess(error));
        const { detail } = error;
        dispatch(paiRequestFailure(detail));
        reject(detail);
      });
  });
};

const paiRequestSuccess = (data) => ({
  type: Types.PAI_REQUEST_SUCCESS,
  data
});

const paiRequestFailure = (error) => ({
  type: Types.PAI_REQUEST_FAILURE,
  error
});

const updateSuggestedQuote = (payload) => dispatch => {
  return new Promise((resolve, reject) => {
    return AppointmentService.updateSuggestedQuote(payload)
      .then(response => {
        const { data: { detail } } = response;
        dispatch(updateSuggestedQuoteSuccess(detail, payload.c24BuyingQuote));
        resolve(detail);
        return response;
      })
      .catch(error => {
        dispatch(updateApiNoAccess(error));
        const { detail } = error;
        dispatch(updateSuggestedQuoteFailure(detail));
        reject(detail);
      });
  });
};
const acceptOcbQuote = (payload) => dispatch => {
  return new Promise((resolve, reject) => {
    return AppointmentService.acceptOcbQuote(payload)
      .then(response => {
        const { data: { detail } } = response;
        resolve(detail);
        return response;
      })
      .catch(error => {
        const { detail } = error;
        dispatch(updateApiNoAccess(error));
        reject(detail);
      });
  });
};

const updateSuggestedQuoteSuccess = (data, c24BuyingQuote) => ({
  type: Types.UPDATE_SUGGESTED_QUOTE_SUCCESS,
  data,
  c24BuyingQuote
});

const updateSuggestedQuoteFailure = (error) => ({
  type: Types.UPDATE_SUGGESTED_QUOTE_FAILURE,
  error
});

const resetNegotiations = () => ({
  type: Types.RESET_NEGOTIATIONS
});

const resetNegotiationsByKey = (key, value) => ({
  type: Types.RESET_NEGOTIATIONS_BY_KEY,
  data: { key, value }
});

const setSuggestedC24Quote = (currentSuggestedC24Quote) => ({
  type: Types.SET_SUGGESTED_C24_QUOTE,
  currentSuggestedC24Quote
});

export {
  fetchNegotiations,
  fetchCarData,
  setCurrentPopup,
  preCheckRaisePAI,
  paiRequest,
  resetNegotiations,
  resetNegotiationsByKey,
  updateSuggestedQuote,
  setSuggestedC24Quote,
  acceptOcbQuote,
  precCheckTokenElite,
  fetchMissedCalls
};
