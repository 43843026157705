import {SLIDER_TYPES, NUMBERS} from '../app-constants';
export const STEP = 1000;
export default (negotiations, step = STEP, customerPage) => {
  let sliderType = SLIDER_TYPES.NA;
  const { data, currentAppointmentIndex } = negotiations;
  const currentAppointmentData = customerPage ? data[0] : data[currentAppointmentIndex] || {};
    const { preCheckData: { conversion } } = currentAppointmentData;
    const showSlider = conversion && (conversion.flag === NUMBERS.ONE || conversion.flag === true);
    const { calculatedc24Quote, c24Quote } = currentAppointmentData;
  const { preCheckData } = currentAppointmentData;
  // console.log("preCheckData-->", preCheckData, '  step-->', step);
    const minPrice = c24Quote > conversion?.min ? c24Quote : conversion?.min;
  const margin = parseFloat(((preCheckData.c24CappingResult || []).filter(({ min_value, max_value }) => {
    return c24Quote >= min_value && c24Quote <= max_value;
  })[0] || {}).margin) || 0; // Used c24Quote in place of calculatedc24Quote
  const priceAcceptance = (conversion && conversion.priceAcceptances) ? conversion.priceAcceptances : [];
  const customMargin = parseFloat((priceAcceptance[priceAcceptance.length - 1] || {}).key || 0);
    const maxPrice = Math.round((c24Quote > conversion?.max ? (c24Quote * 1.03) : conversion?.max) / 1000) * 1000;
  // const { total_pending_counts: { total, pending } } = preCheckData;
  const { limit: { totalLimit, usedLimit }, highestBid, targetPrice, isLimitPaiEnabled } = preCheckData;
  const pendingCount = parseInt(totalLimit) - parseInt(usedLimit);
  let newMaxPrice = maxPrice;
  if (isLimitPaiEnabled){
    let mediumProbablityHighestValue = 0;
    try {
      mediumProbablityHighestValue = priceAcceptance.filter(item => item.band === 'M').pop().key;
    }
    catch {
            
    }
    const hbTPPercent = (highestBid / targetPrice)*100;
    let paiLimit = maxPrice;
    if (mediumProbablityHighestValue > 0){
      if (hbTPPercent < 90){
        paiLimit = Math.max(targetPrice, c24Quote + mediumProbablityHighestValue);
      }else if(hbTPPercent >= 90 && hbTPPercent < 100){
        paiLimit = Math.max(1.1 * targetPrice, c24Quote + mediumProbablityHighestValue);
      } else if (hbTPPercent >= 100 && hbTPPercent < 110){
        paiLimit = Math.max(1.2 * targetPrice, c24Quote + mediumProbablityHighestValue);
      }
    }else{
      if(hbTPPercent < 90){
        paiLimit = targetPrice;
      } else if(hbTPPercent >= 90 && hbTPPercent < 100){
        paiLimit = 1.1 * targetPrice;
      } else if (hbTPPercent > 100 && hbTPPercent < 110){
        paiLimit = 1.2 * targetPrice;
      }
    }
    newMaxPrice = Math.min(paiLimit, maxPrice);
  }
  if ((newMaxPrice - minPrice) <= STEP) {
    newMaxPrice = newMaxPrice + 2 * STEP;
  }
  const domain = [minPrice, newMaxPrice];
  const optimalIncrease = (conversion && conversion.optimal_increase) ? parseFloat((isNaN(conversion.optimal_increase) || conversion.optimal_increase === "") ? 0 : conversion.optimal_increase) : 0;
    const defaultValues = [conversion.optimal];
  const sliderData = currentAppointmentData.conversion.sliderData;
  const {flag, showLimitsFlag} = conversion || {};

  if (flag === true || flag === NUMBERS.ONE) {
    sliderType = SLIDER_TYPES.SLIDER;
	} else if ((flag === false || flag === NUMBERS.ZERO) && (showLimitsFlag === true || showLimitsFlag === NUMBERS.ONE)) {
    sliderType = SLIDER_TYPES.TEXTBOX_WITH_LIMIT;
	} else if ((flag === false || flag === NUMBERS.ZERO) && (showLimitsFlag === false || showLimitsFlag === NUMBERS.ZERO)) {
    sliderType = SLIDER_TYPES.TEXTBOX_NO_LIMIT;
	} else {
    sliderType = SLIDER_TYPES.TEXTBOX_WITH_LIMIT; // backward compatibility in case no data from DS
	}

  return {
    currentAppointmentIndex,
    currentAppointmentData,
    showSlider,
    minPrice,
    maxPrice,
    margin,
    pendingCount,
    domain,
    defaultValues,
    sliderData,
    c24Quote,
    calculatedc24Quote,
    priceAcceptance,
    optimalIncrease,
    step,
    sliderType,
      uuid: conversion?.uuid,
      optimal: c24Quote > conversion?.optimal ? c24Quote : conversion?.optimal,
  };
};