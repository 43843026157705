import React, { useState } from "react";
import styles from "./card-negotiation.module.scss";
import DoneToken from "../../components/images/doneSign.svg";
import PriceNegotiationWrap from "../price-negotiation-wrap";
import CarImageDetail from "../car-image-detail";
import CarDetail from "../car-detail";
import C24Quote from "../c24-quote";
import Button from "../../components/shared/button";
import { isC2CUser, isBikeSource, showToastMessages, getFromLocalStorage } from "../../utils/helper";
import { AppointmentService } from "../../service";
import NegoPriceOffers from "../nego-price-offers";
import { APPOINTMENT_TYPES_STRING, APPOINTMENT_FLAG_KEYS, LOCAL_STORAGE_KEYS, TOKEN_PR_STATUS } from "../../utils/app-constants";
import RaJourney from "../ra-journey";
import tempRA from './images/temp-ra.svg';
import priceFormatter from "../../utils/helpers/price-formatter";
import popupConstants from "../../utils/popup-constants";

const NegotiationCard = ({ 
  data, 
  onClickViewCarDetails, 
  appointmentIndex, 
  quote, 
  isLostLeadFlow, 
  isLostLead, 
  isPll, 
  updateDataList, 
  history,
  appointmentType,
  flagPriority=[],
    precCheckTokenEliteConnect,
    ResetApiNoAccessConnect,
    setCurrentPopupConnect
}) => {
  let isC2C = isC2CUser();
  const isBike = isBikeSource();
  let { appointmentId, dealLostReason, isC2cTokenDone, lastCallStatus, lastCallTime, qualityCall, leadType='', isMonetized=false,  } = data;
  let dealLostForPriceRepo = isLostLead;
  const [isSendPriceClick, setIsSendPriceClick] = useState(false);
  if (!!dealLostReason && isPll) {
    dealLostForPriceRepo = true; 
  };
  const filteredFlagItems = flagPriority.filter(item => !!item.display && !!data[item.key]).sort((a,b)=> a.order - b.order);
  // const filteredFlagItems = flagPriority.filter(item => !!item.display).sort((a,b)=> a.order - b.order);
  const getCallStatusStyleClass = () =>{
    let scCallStatus = "";
    switch(lastCallStatus){
      case "Call connected":
        scCallStatus = "callConnected";
        break;
      case "Call not connected":
        scCallStatus = "callnotConnected";
        break;
      case "Missed Call":
        scCallStatus = "missedCall";
        break;
      case "Not called":
        scCallStatus = "notCalled";
        break;
      default:
    }
    return scCallStatus;
  };
  const onSendPriceSMS = () => {
    setIsSendPriceClick(true);
    const payload = {
      "appointmentId": appointmentId
    };
    AppointmentService.sendPriceSMSBike(payload).then(resp => {
      const {data: {detail: {message}}} = resp;
      showToastMessages(message);
    }).catch(err => {
      showToastMessages(err.detail, false);
    }).finally(()=>{
      setIsSendPriceClick(false);
    });
  };
  const getFlagData = (key, data)=> {
    let returnFlagData = {text: key, css: ''};
    if (key === APPOINTMENT_FLAG_KEYS.LEAD_TYPE) {
      returnFlagData = {text: data[key], css: styles.sellOnline};
      const appLeadType = leadType.toLowerCase();
      if (appLeadType.includes('home')) {
        returnFlagData = {text: data[key], css: styles.homeInspection};
      } else if (appLeadType.includes('hi')) {
        returnFlagData = {text: data[key], css: styles.sohiText};
      }
    } else if (key === APPOINTMENT_FLAG_KEYS.LEAD_MON) {
      returnFlagData = {text: 'Lead Mon', css: styles.leadMon};
    } else if (key === APPOINTMENT_FLAG_KEYS.REPEAT_USER) {
      returnFlagData = {text: 'Repeat User', css: styles.repeatUser};
    } else if (key === APPOINTMENT_FLAG_KEYS.HYPO_APPROVED) {
      returnFlagData = {text: 'Hypo-DSI', css: styles.hypoAutoApproved};
    } else if (key === APPOINTMENT_FLAG_KEYS.AUCTION_LABEL) {
		returnFlagData = {text: data[key], css: styles.auctionLabel};
	} else if (key === APPOINTMENT_FLAG_KEYS.RETARGET_LABEL) {
		returnFlagData = {text: data[key], css: styles.retargetLabel};
	} else if (key === APPOINTMENT_FLAG_KEYS.DIRECT_OCB) {
		returnFlagData = {text: 'Direct OCB', css: styles.directocb};
	}
    return returnFlagData;
  };
  const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "null");

    const onClickGeneratePR = () => {
        ResetApiNoAccessConnect();
        const { appointmentId, tokenPrStatus } = data;
        if (isPll) {
            setCurrentPopupConnect(popupConstants.GENERATE_PR, appointmentIndex, true);
        }
        else {
            if (tokenPrStatus === TOKEN_PR_STATUS.SAVE_AS_DRAFT || tokenPrStatus === TOKEN_PR_STATUS.TOKEN_SUBMIT) {
                history.push({ pathname: `/payment-initiate-token/${appointmentId}`, state: data });
            } else {
                precCheckTokenEliteConnect(appointmentId).then(() => {
                    setCurrentPopupConnect(popupConstants.GENERATE_PR, appointmentIndex, true);
                }).catch(() => {
                    setCurrentPopupConnect(popupConstants.GENERATE_PR, appointmentIndex, true);
                });
            }
        }
    };

  return (
    <div className={styles.negotiationWrapper}>
      <div className={`${styles.cardWrapper} ${data?.isHypoAutoApproved?styles.cardWrapper_hypoDsi:''}`}>
        <div className={styles.watermark_div}>
          {
            [...Array(50).keys()].map((item,index)=>{
              return(
                <p className={styles.watermark} style={{top:`${index*50}px`, left:`${(index*200)%1500}px`}}>
                  {loginData?.email}
                </p>
              );
            })
          }
        </div>
        <div className={styles.imageandDetailsWrapper}>
          <div className={styles.carImageDetail}>
            <CarImageDetail onClick={onClickViewCarDetails} leadData={data} />
            <div className={styles.carTags}>
              {filteredFlagItems.map((flag) => {
                const flagData = getFlagData(flag.key, data);
				return (
					<div>
						{flag.key === APPOINTMENT_FLAG_KEYS.TEMP_ALLOCATE ? 
							<img src={tempRA} alt="temp ra"/> :
                  			<span className={flagData.css}>{flagData.text}</span>
						}
				  	</div>
                );
              })}
            </div>
          </div>
          <div className={styles.carDetail}>
            <CarDetail data={data} isLostLeadFlow={isLostLeadFlow} isLostLead={isLostLead} updateDataList={updateDataList} />
          </div>
        </div>
        <div className={styles.priceNegotiaton}>
          {lastCallStatus &&
          <div className={styles.qualityCallWrapper}>
            <div className={lastCallStatus && `${styles[getCallStatusStyleClass()]}`}>
              <span>{lastCallStatus}</span>
              <span>{lastCallStatus !== "Not called" ? lastCallTime: ""}</span>
            </div>
            {!isBike && qualityCall === true && <p className={styles.qualityCall}>Quality Call</p>}
            {isBike && <Button 
              ctaText="SEND PRICE SMS" 
              classNames="blueButton"
              onClick={onSendPriceSMS}
              disabled={isSendPriceClick}
            />}
          </div>
          }
                  {
                      !!data?.minimumGuaranteedPrice
                          ?
                          <div className={styles.mgp_head}>
                              <div className={styles.mgp_header}>Exchange and Guaranteed pricing</div>
                              <div className={styles.mgp_pricing}>
                                  <div className={styles.mgp_pricing_header}>MGP</div>
                                  <div className={styles.mgp_pricing_price}>₹ {priceFormatter(data?.minimumGuaranteedPrice)}</div>
                              </div>
                              <Button ctaText="Generate MGP PR" classNames="secondaryCta" onClick={onClickGeneratePR} />
                          </div>
                          :
                          <></>
                  }
          <PriceNegotiationWrap data={data} appointmentIndex={appointmentIndex} isLostLeadFlow={isLostLeadFlow} isLostLead={dealLostForPriceRepo} />
        </div>
              {appointmentType === APPOINTMENT_TYPES_STRING.DIY_NEGOTIATION && <div className={styles.priceOffersWrapper}>
                  <NegoPriceOffers data={data} />
          <RaJourney data={data}/>
        </div>}
        <div className={`${styles.quotesWrap} ${quote ? styles.quote : ``}${(isLostLeadFlow || isC2C) ? styles.fixWrapper : ``}`}>
          <C24Quote data={data} appointmentIndex={appointmentIndex} isPll={isPll} updateAppointMent={updateDataList} history={history} />
        </div>
        {(!isC2C && isC2cTokenDone === 1) &&
        <div className={styles.doneToken}>
          <span>
            <img src={DoneToken} alt="done" />
            C2C Token Done
          </span>
        </div>
        }
      </div>
    </div>
  );
};

export default NegotiationCard;
