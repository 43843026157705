import React, { useState, useEffect, useRef } from "react";
import styles from "./recent-selection.module.css";
import { LOCAL_STORAGE_KEYS, LEAD_TYPE } from "../../utils/app-constants";
import { getFromLocalStorage, saveToLocalStorage } from "../../utils/helper";

const RecentStoreSelection = ({
    storeData,
    onToggleStore
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedStores, setSelectedStores] = useState([]);
    const [recentStores, setRecentStores] = useState([]);
    const popupRef = useRef(null);
    const [recentStoresInitialized, setRecentStoresInitialized] = useState(false);

    useEffect(() => {
        const getSelectedStoresFromLocalStorage = () => {
            const storedSelectedStores = getFromLocalStorage(LOCAL_STORAGE_KEYS.SELECTED_STORES) || "";
            const currentSelectedStores = storedSelectedStores.trim() ? storedSelectedStores.split(',') : [];
            setSelectedStores(currentSelectedStores);

            if (!recentStoresInitialized) {
                // Initialize the recent stores list with selected stores first
                const newRecentStores = [...currentSelectedStores];
                setRecentStores(newRecentStores.slice(0, 5));
                saveToLocalStorage(LOCAL_STORAGE_KEYS.RECENT_STORES, JSON.stringify(newRecentStores.slice(0, 5)));
                setRecentStoresInitialized(true);
            } else {
                // Update the recent stores list to prioritize selected stores
                updateRecentStoresWithSelected(currentSelectedStores);
            }
        };

        const getRecentStoresFromLocalStorage = () => {
            const storedRecentStores = getFromLocalStorage(LOCAL_STORAGE_KEYS.RECENT_STORES) || "[]";
            try {
                const parsedRecentStores = JSON.parse(storedRecentStores);
                if (Array.isArray(parsedRecentStores) && parsedRecentStores.length > 0) {
                    setRecentStores(parsedRecentStores.slice(0, 5));
                    setRecentStoresInitialized(true);
                }
            } catch (e) {
                setRecentStores([]);
            }
        };

        getSelectedStoresFromLocalStorage();
        if (!recentStoresInitialized) {
            getRecentStoresFromLocalStorage();
        }

        const handleStorageChange = (e) => {
            if (e.detail && e.detail.key === LOCAL_STORAGE_KEYS.SELECTED_STORES) {
                getSelectedStoresFromLocalStorage();
            }
        };

        window.addEventListener('localStorageChange', handleStorageChange);
        return () => {
            window.removeEventListener('localStorageChange', handleStorageChange);
        };
    }, [recentStoresInitialized]);

    const updateRecentStoresWithSelected = (currentSelectedStores) => {
        // First, create a list that prioritizes selected stores
        let updatedRecentStores = [];

        // 1. Add all currently selected stores to the front (maintaining their current order)
        for (const storeId of currentSelectedStores) {
            if (!updatedRecentStores.includes(storeId)) {
                updatedRecentStores.push(storeId);
            }
        }

        // 2. Fill remaining slots (up to 5) with unselected stores from the existing recent list
        if (updatedRecentStores.length < 5) {
            for (const storeId of recentStores) {
                if (!currentSelectedStores.includes(storeId) && !updatedRecentStores.includes(storeId)) {
                    updatedRecentStores.push(storeId);
                    if (updatedRecentStores.length >= 5) break;
                }
            }
        }

        // Limit to 5 stores
        const limitedRecentStores = updatedRecentStores.slice(0, 5);
        setRecentStores(limitedRecentStores);
        saveToLocalStorage(LOCAL_STORAGE_KEYS.RECENT_STORES, JSON.stringify(limitedRecentStores));
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const getStoreName = (storeId) => {
        if (!storeData || !storeData.length) return storeId;

        const store = storeData.find(item => item.idLmsStore.toString() === storeId.toString());
        return store ? store.name : storeId;
    };

    const isStoreSelected = (storeId) => {
        return selectedStores.includes(storeId);
    };

    const handleStoreClick = (storeId, event) => {
        event.stopPropagation();
        if (onToggleStore) {
            onToggleStore(storeId);
        }
    };

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.filterButtonContainer}>
            <button
                className={styles.filterButton}
                onClick={togglePopup}
                title="Applied Filters"
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 6H20M7 12H17M9 18H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>

            {isOpen && (
                <div className={styles.popupContainer} ref={popupRef}>
                    <div className={styles.popupHeader}>
                        <h5 className={styles.popupTitle}>Applied Filters</h5>
                    </div>
                    <div className={styles.popupContent}>
                        {recentStores.length > 0 ? (
                            <div className={styles.storeChips}>
                                {recentStores.map(storeId => (
                                    <div
                                        key={storeId}
                                        className={`${styles.storeChip} ${isStoreSelected(storeId) ? styles.active : styles.inactive}`}
                                        onClick={(e) => handleStoreClick(storeId, e)}
                                    >
                                        <span className={styles.storeName}>{getStoreName(storeId)}</span>
                                        {isStoreSelected(storeId) ? (
                                            <span className={styles.removeIndicator}>×</span>
                                        ) : (
                                            <span className={styles.addIndicator}>+</span>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className={styles.noFilters}>No filters available</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RecentStoreSelection;