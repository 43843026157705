import { createReducer } from "reduxsauce";
import Types from "./types";
import sortByKey from "../../utils/helpers/sort-by-key";
import { removeDuplicates, getUnique } from "../../utils/helper";

export const INITIAL_STATE = {
  isLoading: true,
  columns: [],
  page: 0,
  data: [],
  error: null,
  total: 0,
  currentCarDetails: null,
  isLoadingCurrentCarDetails: true,
  currentPopup: null,
  currentAppointmentIndex: null,
  preCheckFailure: null,
  isPreChecking: false,
  currentSuggestedC24Quote: null,
  expiryCount: {
    today: 0,
    oneDay: 0,
    twoDays: 0,
    threeDays: 0,
    fourDays: 0,
    fiveDays: 0,
    sixDays: 0,
    sevenDays: 0,
    eightDays: 0,
    nineDays: 0,
    pllFourPlusDays: 0,
    pllTwentyPlusDays: 0,
    total: 0,
    pllFilterCount: 0,
    pllIntent12Days: 0,
    pllIntent34Days: 0,
    pllIntentFivePlusDays: 0,
  },
  loadMore: false,
  filters: null,
  missedCallCount: {
    today: 0,
    yesterday: 0
  },
    flagPriority: [],
    isMgpButtonClicked: false
};

export const fetchNegotiationsSuccess = (state = INITIAL_STATE, { data }) => {
  let _loadMore = true;
  const { columns, totalRows, expires_today, expires_in_1_days, expires_in_2_days, expires_in_3_days, filters,
    expires_in_4_days, expires_in_5_days, expires_in_6_days, expires_in_7_days, expires_in_8_days, expires_in_9_days, expires_in_9plus_days, expires_in_20plus_days, total, pll_filter_count, expires_in_5plus_days, flagPriority } = data;

  let rowData = [...state.data, ...data.data.result];
  // rowData = removeDuplicates(rowData, "appointmentId");
  rowData = getUnique(rowData, "appointmentId");
  if (!!rowData && rowData.length > 0 && (total > rowData.length)) {
    _loadMore = true;
  }
  else {
    _loadMore = false;
  }
  rowData.forEach(item => item.originalInsDateTime = new Date(item.originalInsDate).getTime());
  // const sortedRowData = rowData;//sortByKey(rowData, "originalInsDateTime", "desc");
  const expiryCount = {
    today: expires_today || 0,
    oneDay: expires_in_1_days || 0,
    twoDays: expires_in_2_days || 0,
    threeDays: expires_in_3_days || 0,
    fourDays: expires_in_4_days || 0,
    fiveDays: expires_in_5_days || 0,
    sixDays: expires_in_6_days || 0,
    sevenDays: expires_in_7_days || 0,
    eightDays: expires_in_8_days || 0,
    nineDays: expires_in_9_days || 0,
    pllFourPlusDays: expires_in_9plus_days || 0,
    pllTwentyPlusDays: expires_in_20plus_days || 0,
    total: total || 0,
    pllFilterCount: pll_filter_count || total,
    pllIntent12Days: expires_in_1_days + expires_in_2_days,
    pllIntent34Days: expires_in_3_days + expires_in_4_days,
    pllIntentFivePlusDays: expires_in_5plus_days,
  };
  return { ...state, columns, total: totalRows, data: rowData, isLoading: false, error: null, expiryCount, loadMore: _loadMore, filters, flagPriority };
};

export const fetchNegotiationsFailure = (state = INITIAL_STATE, { error }) => {
  return { ...state, error, isLoading: false };
};

export const fetchMissedCallsSuccess = (state = INITIAL_STATE, {data}) => {
  const missedCalls = {
    today: data?.today || 0,
    yesterday: data?.yesterday || 0
  };
  return {...state, missedCallCount: missedCalls};
};

export const fetchMissedCallsFailure = (state = INITIAL_STATE) => {
  const missedCalls = {
    today: 0,
    yesterday: 0
  };
  return {...state, missedCallCount: missedCalls};
};

export const fetchCarDataSuccess = (state = INITIAL_STATE, { data, appointmentId }) => {
  const currentCarDetails = data[appointmentId];
  return { ...state, isLoadingCurrentCarDetails: false, currentCarDetails };
};

// export const fetchCarDataFailure = (state = INITIAL_STATE, { error }) => {
//     return { ...state, error, isLoadingCurrentCarDetails: false };
// };
export const fetchCarDataFailure = (state = INITIAL_STATE) => {
  return { ...state, isLoadingCurrentCarDetails: false };
};

export const setCurrentPopup = (state = INITIAL_STATE, { currentPopup, currentAppointmentIndex, isMgpButtonClicked = false }) => {
    return { ...state, currentPopup, currentAppointmentIndex, isMgpButtonClicked };
};

export const preChecking = (state = INITIAL_STATE) => {
  return { ...state, isPreChecking: true };
};

// export const preCheckRaisePAISuccess = (state = INITIAL_STATE, { data: { result } }) => {
//     debugger;
//     const updatedDataState = state.data.map((appointment) => {
//         if (appointment.appointmentId === result.appointment_id) {
//             appointment.preCheckData = result;
//         }
//         return appointment;
//     });
//     return { ...state, data: updatedDataState, isPreChecking: false };
// };
export const preCheckRaisePAISuccess = (state = INITIAL_STATE, { data }) => {
  const updatedDataState = state.data.map((appointment) => {
    if (appointment.appointmentId === data.appointment_id) {
      appointment.preCheckData = data;
    }
    return appointment;
  });
  return { ...state, data: updatedDataState, isPreChecking: false };
};
export const preCheckTokenEliteSuccess = (state = INITIAL_STATE, { data }) => {
  const updatedDataState = state.data.map((appointment) => {
    if (appointment.appointmentId === data.appointment_id) {
      appointment.tokenEliteAbTest = data;
    }
    return appointment;
  });
  return { ...state, data: updatedDataState, isPreChecking: false };
};

export const preCheckRaisePAIFailure = (state = INITIAL_STATE) => {
  return { ...state, isPreChecking: false };
};

export const paiRequestSuccess = (state = INITIAL_STATE) => {
  return { ...state };
};

export const paiRequestFailure = (state = INITIAL_STATE) => {
  return { ...state };
};

export const updateSuggestedQuoteSuccess = (state = INITIAL_STATE, { c24BuyingQuote }) => {
  const index = state.currentAppointmentIndex;
  const updatedDataState = state.data.concat();
  updatedDataState[index] = { ...state.data[index], c24Quote: c24BuyingQuote };
  return { ...state, data: updatedDataState };
};

export const updateSuggestedQuoteFailure = (state = INITIAL_STATE) => {
  return { ...state };
};

export const resetNegotiations = () => {
  return INITIAL_STATE;
};

export const resetNegotiationsByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const setSuggestedC24Quote = (state = INITIAL_STATE, { currentSuggestedC24Quote }) => {
  return { ...state, currentSuggestedC24Quote };
};

export const HANDLERS = {
  [Types.FETCH_NEGOTIATIONS_SUCCESS]: fetchNegotiationsSuccess,
  [Types.FETCH_NEGOTIATIONS_FAILURE]: fetchNegotiationsFailure,
  [Types.FETCH_CAR_DATA_SUCCESS]: fetchCarDataSuccess,
  [Types.FETCH_CAR_DATA_FAILURE]: fetchCarDataFailure,
  [Types.SET_CURRENT_POPUP]: setCurrentPopup,
  [Types.PRECHECK_RAISE_PAI]: preChecking,
  [Types.PRECHECK_RAISE_PAI_SUCCESS]: preCheckRaisePAISuccess,
  [Types.PRECHECK_RAISE_PAI_FAILURE]: preCheckRaisePAIFailure,
  [Types.PAI_REQUEST_SUCCESS]: paiRequestSuccess,
  [Types.PAI_REQUEST_FAILURE]: paiRequestFailure,
  [Types.RESET_NEGOTIATIONS]: resetNegotiations,
  [Types.RESET_NEGOTIATIONS_BY_KEY]: resetNegotiationsByKey,
  [Types.UPDATE_SUGGESTED_QUOTE_SUCCESS]: updateSuggestedQuoteSuccess,
  [Types.UPDATE_SUGGESTED_QUOTE_FAILURE]: updateSuggestedQuoteFailure,
  [Types.SET_SUGGESTED_C24_QUOTE]: setSuggestedC24Quote,
  [Types.PRECHECK_TOKEN_ELITE_SUCCESS]: preCheckTokenEliteSuccess,
  [Types.FETCH_MISSEDCALLS_SUCCESS]: fetchMissedCallsSuccess,
  [Types.FETCH_MISSEDCALLS_FAILURE]: fetchMissedCallsFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
