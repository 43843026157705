/* eslint-disable import/no-anonymous-default-export */
import {SLIDER_TYPES, NUMBERS} from '../app-constants';
export const STEP = 1000;

export default ({ preCheckData = {}, pllMargin = 0, step = STEP, isRetailOCB = false, isDirectOcb = false, c24Quote = 0 }) => {
    let sliderType = SLIDER_TYPES.NA;
    if (isDirectOcb) {
        const {min=0, optimal=0, max=1, priceAcceptances=[],uuid} = preCheckData?.conversion || {};
        const acceptanceRanges = priceAcceptances.map(item => {
            return {
                key: item.key,
                value: item.acceptanceProbability,
			band: item.band,
		};
	});
	return {
		showSlider: true,
		minPrice: min,
		priceAcceptance: acceptanceRanges,
		sliderData: null,
		defaultValues: [optimal],
		domain: [min, max],
		pendingRequest: 0,
		step,
		sliderType: SLIDER_TYPES.SLIDER,
		maxPrice: max,
		optimalPrice: optimal,
        uuid:uuid
	};
  } else if (isRetailOCB) {
        const { conversion } = preCheckData || {};
        const optimalPrice = c24Quote > conversion?.optimal ? c24Quote : conversion?.optimal;
	const priceAcceptance = (conversion && conversion.priceAcceptances) ? conversion.priceAcceptances : [];
	const showSlider = (conversion && (conversion.flag === NUMBERS.ONE || conversion.flag === true)) && (!!priceAcceptance && priceAcceptance.length > 0);
        const minPrice = !!conversion ? c24Quote > conversion.min ? c24Quote : conversion.min : 0;
	const customMargin = !!priceAcceptance && priceAcceptance.length > 0 ?
    parseFloat((priceAcceptance[priceAcceptance.length - 1] || {}).key || 0) : 0;
        const maxPrice = Math.round((!!conversion ? c24Quote > conversion.max ? (c24Quote * 1.03) : conversion.max : 0) / 1000) * 1000;
	const domain = [minPrice, maxPrice];
        const defaultValues = [optimalPrice]; 
	const sliderData = null; 

	const {flag, showLimitsFlag} = conversion || {};
			
	if (flag === true || flag === NUMBERS.ONE) {
		sliderType = SLIDER_TYPES.SLIDER;
    } else if ((flag === false || flag === NUMBERS.ZERO) && (showLimitsFlag === true || showLimitsFlag === NUMBERS.ONE)) {
		sliderType = SLIDER_TYPES.TEXTBOX_WITH_LIMIT;
	} else if ((flag === false || flag === NUMBERS.ZERO) && (showLimitsFlag === false || showLimitsFlag === NUMBERS.ZERO)) {
		sliderType = SLIDER_TYPES.TEXTBOX_NO_LIMIT;
	} else {
		sliderType = SLIDER_TYPES.TEXTBOX_NO_LIMIT; // backward compatibility in case no data from DS
	}

	return {
		showSlider,
		minPrice,
		priceAcceptance,
		sliderData,
		defaultValues,
		domain,
		pendingRequest: 0,
		step,
		sliderType,
        uuid: conversion?.uuid,
        optimalPrice
	};
  } else {
	const { ocbData } = preCheckData || {};
	const priceAcceptance = (ocbData && ocbData.priceAcceptances) ? ocbData.priceAcceptances : [];
        const optimalPrice = c24Quote > ocbData?.optimal ? c24Quote : ocbData?.optimal;

	const showSlider = (ocbData && (ocbData.flag === NUMBERS.ONE || ocbData.flag === true)) && (!!priceAcceptance && priceAcceptance.length > 0);
	// const { total_pending_counts: { pending, total } } = preCheckData;
	const { limit: { usedLimit=0, totalLimit=0 } = {}} = preCheckData || {};
	const pendingRequest = (parseInt(totalLimit) >= 0 && parseInt(usedLimit) >= 0) ? (totalLimit - usedLimit) : 0;

        const minPrice = !!ocbData ? c24Quote > ocbData.min ? c24Quote : ocbData.min : 0;
        const minPriceForHigherValue = !!ocbData ? ocbData.min : 0;
	const margin = 0;

	const customMargin = !!priceAcceptance && priceAcceptance.length > 0 ?
			parseFloat((priceAcceptance[priceAcceptance.length - 1] || {}).key || 0) : 0;
        const maxPrice = Math.round((!!ocbData ? c24Quote > ocbData.max ? (c24Quote * 1.03) : ocbData.max : 0) / 1000) * 1000;
	const domain = [minPrice, maxPrice];
        const defaultValues = [optimalPrice ?? minPrice]; // Used c24Quote in place of calculatedc24Quote
	const sliderData = null;// currentAppointmentData.conversion.sliderData;
	const {flag, showLimitsFlag} = ocbData || {};
    
	if (flag === NUMBERS.ONE || flag === true) {
        sliderType = SLIDER_TYPES.SLIDER;
	} else if ((flag === NUMBERS.ZERO || flag === false) && (showLimitsFlag === NUMBERS.ONE || showLimitsFlag === true)) {
        sliderType = SLIDER_TYPES.TEXTBOX_WITH_LIMIT;
	} else if ((flag === NUMBERS.ZERO || flag === false) && (showLimitsFlag === NUMBERS.ZERO || showLimitsFlag === false)) {
        sliderType = SLIDER_TYPES.TEXTBOX_NO_LIMIT;
	} else {
        sliderType = SLIDER_TYPES.TEXTBOX_NO_LIMIT; // backward compatibility in case no data from DS
	}

	return {
		showSlider,
		minPrice,
		priceAcceptance,
		sliderData,
		defaultValues,
		domain,
		pendingRequest,
		step,
		sliderType,
        uuid: ocbData?.uuid,
        optimalPrice
	};
  }
};