import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NegotiationCard from "./component";
import { precCheckTokenElite, setCurrentPopup } from "../negotiation-list/actions";
import { resetApiNoAccess } from "../hoc-access-controller/actions";

const mapStateToProps = ({
  lostLeadsearch,
  negotiations: {flagPriority}
}) => ({
  lostLeadsearch,
  flagPriority
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    precCheckTokenEliteConnect: precCheckTokenElite,
    ResetApiNoAccessConnect: resetApiNoAccess,
    setCurrentPopupConnect: setCurrentPopup
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NegotiationCard);
