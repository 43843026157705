import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./lead-menu.module.scss";
import { LOCAL_STORAGE_KEYS, RESET_REDUCER_STATE_KEYS, ROLE_KEYS, LEAD_TYPE, RM_ROLES } from "../../utils/app-constants";
import { getFromLocalStorage, saveToLocalStorage, isPllUser, getIndividualOrStoreName, getStoreIdForSingleStoreAssignned, isCJManager } from "../../utils/helper";
import IndividualsMenu from "../individuals-menu";
import StoresMenu from "../stores-menu";
import Correct from "../images/correct.svg";
import LayoutContext from "../layout/context";

const LEAD_MENU = {
  STORE: "Branch",
  INDIVIDUAL: "Individual"
};

const IGNORED_PATH_NAMES = [
  'check-in',
  'detail',
  'lost-leads',
  'pll-lead'
];

const LeadMenu = (props) => {
  const {
    resetLeadMenuByKeyConnect,
    fetchAppointmentCountsConnect,
    location,
    history,
    resetNotificationnByKeyConnect,
    data, 
  } = props;
  const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  const {role, originalRole} = JSON.parse(localData) || {};
  const isPll = isPllUser();
  const isCjm = originalRole === ROLE_KEYS.CJM;
  const [showLeadsMenu, setShowLeadsMenu] = useState(false);
  const { pathname } = location;
  const [isActiveMenu, setIsActiveMenu] = useState(false);
  const [subMenus, setSubMenus] = useState([]);
  const leadType = getFromLocalStorage(LOCAL_STORAGE_KEYS.LEADS);
    const [selectedFilter, setSelectedFilter] = useState(null);
    // Create a ref for the parent container
    const menuRef = useRef(null);

  useEffect(()=>{
    if (data.child) {
      const childMenus = data.child.sort((a,b)=> a.displayOrder - b.displayOrder);
      setSubMenus(childMenus);
    }
  },[]);

  useEffect(() => {
    checkActiveMenu();
  }, [pathname]);

    // Add event listener to detect clicks outside the menu
    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        }
        if (showLeadsMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showLeadsMenu]);

  const openMenu = () => {
    setShowLeadsMenu(true);
  };

  const closeMenu = () => {
    setShowLeadsMenu(false);
  };

    const updateSelectedFilter = (val) => {
        setSelectedFilter(val);
    };

  const checkActiveMenu = () => {
      !IGNORED_PATH_NAMES.find(path => path === pathname.replace('/', '')) ? setIsActiveMenu(true) : setIsActiveMenu(false);
  };

  const onSelectionChange = (context) => {
    const storeOrAssocaiateName = getIndividualOrStoreName();
    !!context && context.updateLayoutContext(storeOrAssocaiateName);
    const singleStoreAssignedId = getStoreIdForSingleStoreAssignned();
    if (singleStoreAssignedId > 0) {
      resetNotificationnByKeyConnect("fbStoreId", singleStoreAssignedId);
    } else {
      resetNotificationnByKeyConnect("fbStoreId", 0);
    }
    if (!IGNORED_PATH_NAMES.find(item => pathname.includes(item))) {
      resetLeadMenuByKeyConnect(RESET_REDUCER_STATE_KEYS.RESET_AND_FETCH_DATA, true);
      closeMenu();
      fetchAppointmentCountsConnect();
    } else if (isCjm) {
      history.push("/scheduled");
    } else {
      history.push("/negotiation");
    }
  };

  const onStoreClick = (context) => {
    const storeOrAssocaiateName = getIndividualOrStoreName();
    !!context && context.updateLayoutContext(storeOrAssocaiateName);
    const singleStoreAssignedId = getStoreIdForSingleStoreAssignned();
    if (singleStoreAssignedId > 0) {
      resetNotificationnByKeyConnect("fbStoreId", singleStoreAssignedId);
    } else {
      resetNotificationnByKeyConnect("fbStoreId", 0);
    }
    if (role.toLowerCase() === ROLE_KEYS.RA) {
      saveToLocalStorage(LOCAL_STORAGE_KEYS.LEADS, LEAD_TYPE.STORE);
      onSelectionChange();
    }
  };

  return (
    <LayoutContext.Consumer>
      {context => (
              <div
                  className={styles.leadsWrapper}
                  ref={menuRef} // Add the ref to the parent container
              >
          <p className={`${isActiveMenu ? styles.titleActive : ''}`} onMouseEnter={openMenu}>{data.string} <span>{context.data.associateName && `(${context.data.associateName})`}</span></p>
          <div className={`${styles.leadsDropdown} ${showLeadsMenu ? styles.active : ''}`}>
            <ul>
              {
                subMenus.map(item => {
                  return (
                      <li
                          key={item.pKey}
                          className={item.pKey === selectedFilter ? styles.filteractive : ''}
                          onMouseEnter={() => { updateSelectedFilter(item.pKey); }}
                      >
                      {
                        item.pKey === LEAD_MENU.INDIVIDUAL &&
                        <>
                          <span className={styles.subChildArrow} />
                          {leadType === LEAD_TYPE.INDIVIDUAL &&
                          <img className={styles.rightSign} src={Correct} alt="Right" />
                          }
                          <Link to="#">{item.pText}</Link>
                          <div className={styles.child}>
                            <IndividualsMenu
                              originalRole={originalRole}
                              isPllUser={isPll}
                              onSelectionChange={() => onSelectionChange(context)}
                              showLeadsMenu={showLeadsMenu}
                            />
                          </div>
                        </>
                      }
                      {
                        item.pKey === LEAD_MENU.STORE &&
                        <>
                          {leadType === LEAD_TYPE.STORE &&
                          <img className={styles.rightSign} src={Correct} alt="Right" />
                          }
                          <Link to="#" onClick={() => onStoreClick(context)}>{item.pText}</Link>
                          {   
                            <>
                              <div className={styles.child}>
                                <StoresMenu
                                  originalRole={originalRole}
                                  onSelectionChange={() => onSelectionChange(context)}
                                  showLeadsMenu={showLeadsMenu}
                                />
                              </div>
                            </>
                          }
                        </>
                      }
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div >
      )}
    </LayoutContext.Consumer>
  );
};
export default LeadMenu;