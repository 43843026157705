import React, { useState, useEffect } from "react";
import H2 from "../h2/component";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Button from "../shared/button/component";
import RadioButton from "../shared/radio-button/component";
import priceFormatter from "../../utils/helpers/price-formatter";
import { redirectToUrl, showToastMessages, isPllUser, getFromLocalStorage, isBikeSource, getDiySortOrderKey, calculateVisiblePrice } from "../../utils/helper";
import styles from "./generate-pr-popup.module.css";
import { AppointmentService } from "../../service";
import SelectComponent from "../shared/select/component";
import { LOCAL_STORAGE_KEYS, NUMBERS, DIY_SORT_ORDER } from "../../utils/app-constants";

const useStyles = makeStyles(theme => {
  return {
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontSize: '10px',
      width: '100%'
    }
  };
});

const GeneratePrPopup = ({ negotiations, close, updateSuggestedQuoteConnect, setSuggestedC24QuoteConnect, resetListing, history }) => {
    const { data, currentAppointmentIndex, currentSuggestedC24Quote, isMgpButtonClicked } = negotiations;
  const currentAppointmentData = data[currentAppointmentIndex] || {};
    const { customerName, make, model, year, c24Quote, calculatedc24Quote, bestC24Quote, adminUrl, appointmentId, sortOrder, diySortOrder, negotiated_price, expected_hb, tokenEliteAbTest, currentHB, isInventoryBuying, pllLimitC24Quote, negativeMargin, diy_pll_shared_price, diy_pll_price_status, isRetailMarginAllowed, minimumGuaranteedPrice } = currentAppointmentData;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [inputText, setInputText] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [labelText, setLabelText] = useState("Final C24 Quote");
  const [ownerTypeList, setOwnerTypeList] = useState([]);
  const [ownerType, setOwnerType] = useState("");
    const [procurementType, setProcurementType] = useState("");
    const [procurementErrorClass, setProcurementErrorClass] = useState("");
  const classes = useStyles();
  let isPll = isPllUser();
  let displayPrice = c24Quote;
  // let negativeMarginLimit = c24Quote * 1.2;
  let minimumPrice = 0;// sortOrder === 20 ? Math.round(negotiated_price - (negotiated_price * .15)) : Math.round(c24Quote - (c24Quote * .15));
  const isBike = isBikeSource();
  const currentState = getDiySortOrderKey(diySortOrder, sortOrder === 42);
    const isC24QuoteEditable = () => {
    if (isPll && !isBike && !!negativeMargin) {
      return (NUMBERS.TWO_LAKHS - pllLimitC24Quote) >= NUMBERS.ZERO;
    } else {
      return true;
    }
  };
  if (isPll) {
    // displayPrice =  [23, 24].includes(sortOrder) ?  c24Quote : (sortOrder === 20 || (
    //     sortOrder === 42 && [11, 14, 18].includes(diySortOrder) && !!diy_pll_price_status 
    // ))  ? diy_pll_shared_price : expected_hb;
    switch (sortOrder) {
      case 23:
        case 24:
        displayPrice = c24Quote;
        break;
      case 20:
        displayPrice = isRetailMarginAllowed ? c24Quote : currentHB;
        break;
      case 15:
      case 12:
        displayPrice = isRetailMarginAllowed ? c24Quote : expected_hb;
        break;
      case 42:
        if ([11, 14, 18].includes(diySortOrder) && !!diy_pll_price_status) {
            displayPrice = diy_pll_shared_price;
        } else if (diySortOrder === 19) {
          displayPrice = c24Quote;
        } else {
          displayPrice = currentHB;
        }
        break;
      default:
        displayPrice = expected_hb;
        break;
    }
    minimumPrice = calculatedc24Quote;
  }
  else {
    displayPrice = c24Quote;
    minimumPrice = calculatedc24Quote;
  }
  minimumPrice = Math.round(minimumPrice - (minimumPrice * .15));
  const [minPrice, setMinPrice] = useState(minimumPrice);
  const [errorClass, setErrorClass] = useState("");
  const [dealerErrorClass, setDealerErrorClass] = useState("");
  const [tokenEliteFlow, setTokenEliteFlow] = useState(((!!tokenEliteAbTest && tokenEliteAbTest.status === 1) && !isPll) ? true : false);
  const [spotToken, setSpotToken] = useState("ONTHESPOT");
    const [maxPrice, setMaxPrice] = useState(null);

  useEffect(() => {
      appointmentId && AppointmentService.getCheckAndAllowPR(appointmentId).then((resp => {
          const { data: { detail: { canCreateRaTokenFlow = 0 } } } = resp;
      if (canCreateRaTokenFlow === 1) {
        AppointmentService.getOwnerShipType()
          .then(response => {
            const { data: { detail } } = response;
            setOwnerTypeList(detail);
          }).catch(error => {
            console.log(error);
          });
      }
    }));
    setSuggestedC24QuoteConnect(displayPrice);
      setInputText(priceFormatter(calculateVisiblePrice(currentAppointmentData?.manualQuote, currentAppointmentData?.dynamicMarginExp, currentAppointmentData?.c24Quote, currentAppointmentData?.inputAmount, currentAppointmentData?.pllMargin, currentAppointmentData?.retailMargin, isPll)));
    if (sortOrder === 20) {
      setLabelText("Guaranteed Closure Price");
    }
  }, []);

    useEffect(() => {
        // Update max price based on procurement type selection
        if (minimumGuaranteedPrice && procurementType === "inventory") {
            setMaxPrice(minimumGuaranteedPrice);
        } else {
            setMaxPrice(null);
        }
    }, [procurementType, minimumGuaranteedPrice]);

  const showDisplayLowMargin = () => {
    let marginType = !!tokenEliteAbTest && !!tokenEliteAbTest.marginType ? tokenEliteAbTest.marginType : '';
    if (marginType === "Low Margin") {
      return (
        <small className={styles.lowMargin}>Low Margin on this Car.</small>
      );
    }
    else if (marginType === "Negative Margin") {
      // negative margin
      return (
        <small className={styles.negative}>Negative Margin on this Car.</small>
      );
    }
  };

  const onChangeText = (e) => {
    setErrorClass("");
    const value = e.target.value && e.target.value.replace(/,/g, '') ? priceFormatter(Number(e.target.value.replace(/,/g, ''))) : "";
    const cPrice = Number(e.target.value.replace(/,/g, ''));
    setSuggestedC24QuoteConnect(cPrice);
      setInputText(value);

      // Validate based on procurement type and MGP if applicable
      if (minimumGuaranteedPrice && procurementType === "inventory" && cPrice > minimumGuaranteedPrice) {
          setErrorClass("form-text text-danger");
          return;
      }

    if (!!bestC24Quote && !isPll && currentState === DIY_SORT_ORDER.DIY_RA_NEGO_VIEW) {
      if (cPrice < calculatedc24Quote || cPrice > bestC24Quote) {
        setErrorClass("form-text text-danger");
      }
    }
  };

  const onOwnerTypeSelect = (value) => {
    setDealerErrorClass("");
    setOwnerType(value);
  };

    const onProcurementTypeSelect = (value) => {
        setProcurementErrorClass("");
        setProcurementType(value);

        // Clear any existing error for price input when procurement type changes
        setErrorClass("");

        // If switching to inventory and current price is higher than MGP, reset price to MGP
        if (value === "inventory" && minimumGuaranteedPrice && currentSuggestedC24Quote > minimumGuaranteedPrice) {
            const formattedMGP = priceFormatter(minimumGuaranteedPrice);
            setInputText(formattedMGP);
            setSuggestedC24QuoteConnect(minimumGuaranteedPrice);
        }
    };

  const onSubmitSuggestedQuote = () => {
    setErrorClass("");
    setDealerErrorClass("");
      setProcurementErrorClass("");

      // Validate procurement type if MGP exists
      if (minimumGuaranteedPrice && procurementType === "") {
          setProcurementErrorClass("form-text text-danger");
          return;
      }

      // Validate price based on procurement type
      if (!minimumGuaranteedPrice && minimumPrice > currentSuggestedC24Quote) {
      setErrorClass("form-text text-danger");
    }
      else if (minimumGuaranteedPrice && procurementType === "inventory" && currentSuggestedC24Quote > minimumGuaranteedPrice) {
          setErrorClass("form-text text-danger");
      }
      else if (!minimumGuaranteedPrice && !!pllLimitC24Quote && isPll && !isBike && currentSuggestedC24Quote > pllLimitC24Quote) {
      setErrorClass("form-text text-danger");
    }
    else if (tokenEliteFlow && ownerType === "") {
      setDealerErrorClass("form-text text-danger");
    }
    else {
      if (spotToken === "TOKEN") {
        onSubmitPrToken();
      } else if (spotToken === "ONTHESPOT") {
        setIsSubmitted(true);
        setisLoading(true);
        const payload = {
          "appointmentId": appointmentId,
            "c24BuyingQuote": currentSuggestedC24Quote,
        };
        updateSuggestedQuoteConnect(payload)
          .then(() => {
            const params = {
                "appointmentId": appointmentId,
                "procurementType": procurementType == 'inventory' ? 'INVENTORY' : null
            };
            AppointmentService.postOnSpotRedirectionUrl(params)
                .then((resp) => {
                    const { data: { detail, status } } = resp;
                if (status === 200) {
                    const { url = '' } = detail || {};
                  url && redirectToUrl(url);
                } else if (status === 208) {
                  showToastMessages(detail, false);
                }
                }).catch(err => {
                    const { detail } = err;
                showToastMessages(detail, false);
                }).finally(() => {
                setIsSubmitted(false);
                setisLoading(false);
                resetListing();
                close();
              });
          })
          .catch((resp) => {
            showToastMessages(resp, false);
            setIsSubmitted(false);
            setisLoading(false);
          });
      }
    }
  };

  const onSubmitPrToken = () => {
    try {
      SubmitTokenToAdmin(spotToken);
    }
    catch (exception) {

    }
  };
  const SubmitTokenToAdmin = (customerIntent) => {
    setIsSubmitted(true);
    setisLoading(true);
    const payload = {
      "appointmentId": appointmentId,
        "c24BuyingQuote": currentSuggestedC24Quote,
    };
    updateSuggestedQuoteConnect(payload)
        .then((resp) => {
        AppointmentService.getPrEnable(appointmentId)
          .then(response => {
            const { detail: { details: { canCreateTokenFlow } } } = response.data;
            if (canCreateTokenFlow && canCreateTokenFlow === 1) {
              prInitiateToken();
            } else {
              showToastMessages("You Can't create token", false);
            }
          });
      })
        .catch((err) => {
        console.log("err", err);
        showToastMessages("Failed to create token", false);
      })
        .finally(() => {
        setIsSubmitted(false);
        setisLoading(false);
        close();
        resetListing();
      });
  };

  const prInitiateToken = () => {
    const payload = {
        appointmentId: appointmentId,
        "procurementType": procurementType == 'inventory' ? 'INVENTORY' : null
    };
    setIsSubmitted(true);
    setisLoading(true);
    AppointmentService.prInitiateToken(payload)
      .then(response => {
        const { status } = response.data;
        if (status === 201) {
            history.push({ pathname: `/payment-initiate-token/${appointmentId}`, state: currentAppointmentData });
        }
      }).catch(error => {
          const { status } = error;
        if (status === 400) {
            history.push({ pathname: `/payment-initiate-token/${appointmentId}`, state: currentAppointmentData });
        };
      }).finally(() => {
        setIsSubmitted(false);
        setisLoading(false);
        close();
      });
  };

  const onRadioChange = (customerIntent) => {
    setSpotToken(customerIntent);
  };

  return (
    <div className={styles.popupWrap}>
      <H2 text="GENERATE PR" />
      <ul className={styles.customerDesc}>
        <li>{customerName}</li>
        <li>{`${make} ${model}`}</li>
        <li>{year}</li>
      </ul>
          {minimumGuaranteedPrice &&
              <div className={styles.selectOwnership}>
                  <SelectComponent
                      name={"procurement_type"}
                      placeholder={"Select Procurement Type"}
                      optionsList={[
                          ...(!isMgpButtonClicked ? [{
                              label: 'Dealer (Non-Inventory)',
                              value: 'dealer'
                          }] : []),
                          ...(isMgpButtonClicked ? [{
                              label: 'Inventory',
                              value: 'inventory'
                          }] : []),
                      ]}
                      onChange={onProcurementTypeSelect}
                      defaultValue={isMgpButtonClicked ? 'inventory' : 'dealer'}
                  />
                  <small key="info" className={`${procurementErrorClass}`}>{`* Required`}</small>
              </div>
          }

      <div className={styles.textField}>
        <TextField
          label={labelText}
          className={classes.textField}
          margin="dense"
          variant="outlined"
          onChange={onChangeText}
          value={inputText}
          inputProps={{
            maxLength: 9,
          }}
                  disabled={
                      (
                          minimumGuaranteedPrice && procurementType == 'inventory' ? false :
                              (isInventoryBuying ? isInventoryBuying : !isC24QuoteEditable())
                      )
                  }
          InputLabelProps={{ style: {} }}
        />
        {!pllLimitC24Quote && !isPll && currentState === DIY_SORT_ORDER.DIY_RA_NEGO_VIEW && !!bestC24Quote && <small key="info" className={`${errorClass}`}>{`Enter price between Rs ${priceFormatter(calculatedc24Quote)} & ${priceFormatter(bestC24Quote)}`}</small>}
              {procurementType !== "inventory" && !pllLimitC24Quote && !bestC24Quote && !maxPrice && <small key="info" className={`${errorClass}`}>{`Enter price above Rs ${priceFormatter(minPrice)} only.`}</small>}
              {procurementType !== "inventory" && !pllLimitC24Quote && !bestC24Quote && maxPrice && <small key="info" className={`${errorClass}`}>{`Enter price between Rs ${priceFormatter(minPrice)} and Rs ${priceFormatter(maxPrice)}.`}</small>}
              {procurementType !== "inventory" && !!pllLimitC24Quote && isPll && !isBike && isC24QuoteEditable() && <p key="info" className={!!errorClass ? errorClass : styles.errorMsg}>You can enter price upto <span>{`${priceFormatter(pllLimitC24Quote)}.`}</span></p>}
              {maxPrice && procurementType === "inventory" && <p key="mgp-info" style={{ fontSize: '12px' }} className={`${errorClass}`}>For inventory procurement, max price is capped at MGP: <span>{`${priceFormatter(maxPrice)}`}</span></p>}
        {showDisplayLowMargin()}
      </div>

      {ownerTypeList && ownerTypeList.length > 0 &&
              <div className={styles.selectOwnership}>
                  <SelectComponent
                      name={"owner_type"}
                      placeholder={"Select Ownership Type"}
                      optionsList={ownerTypeList.map((item) => {
                          return {
                              label: item,
                              value: item
                          };
                      })}
                      onChange={onOwnerTypeSelect}
                  />
                  <small key="info" className={`${dealerErrorClass}`}>{`* Required`}</small>
              </div>
      }

      <div className={styles.getQuote}>
        <div className={styles.radioButton}>
          <RadioButton
            value="TOKEN"
            key="TOKEN"
            id="TOKEN"
            checkedStatus={spotToken === "TOKEN"}
            onClickCallback={() => { onRadioChange("TOKEN"); }}
          />
          <div><p className="mt-3">Token</p></div>
          <RadioButton
            value="ONTHESPOT"
            key="ONTHESPOT"
            id="ONTHESPOT"
            checkedStatus={spotToken === "ONTHESPOT"}
            onClickCallback={() => { onRadioChange("ONTHESPOT"); }}
          />
          <div><p className="mt-3">On Spot</p></div>
        </div>
      </div>
      <div className={styles.sumbmitPR}>
        <Button
          isLoading={isLoading}
                  disabled={isSubmitted || !!errorClass || (minimumGuaranteedPrice && procurementType === "") || !inputText}
          onClick={() => onSubmitSuggestedQuote()}
          classNames="blueButton"
          ctaText={"Submit"} />
      </div>
    </div>
  );
};

export default GeneratePrPopup;